import { FETCH_AUDIT_LOG_START, FETCH_AUDIT_LOG_SUCCESS, FETCH_AUDIT_LOG_FAILED } from "../actions/actionTypes";

const initialState = {};

export const auditLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AUDIT_LOG_START:
      return { ...state, loading: true };

    case FETCH_AUDIT_LOG_SUCCESS:
      return { ...state, loading: false, auditLogInfo: action.result };

    case FETCH_AUDIT_LOG_FAILED:
      return { ...state, loading: false };

    default:
      return state;
  }
};
