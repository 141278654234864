import {
  RESOURCES_FETCH_SUCCESS,
  RESOURCES_FETCH_FAILED,
  GET_IP_LOCATION_SUCCESS,
  GET_IP_LOCATION_FAILED,
  FONTS_FETCH_SUCCESS,
  FONTS_FETCH_FAILED,
  UNSPLASH_DOWNLOAD_START,
  UNSPLASH_DOWNLOAD_SUCCESS,
  UNSPLASH_DOWNLOAD_FAILED,
  FETCH_CUSTOM_FONTS_START,
  FETCH_CUSTOM_FONTS_SUCCESS,
  FETCH_CUSTOM_FONTS_FAILED,
  UPLOAD_CUSTOM_FONTS_START,
  UPLOAD_CUSTOM_FONTS_SUCCESS,
  UPLOAD_CUSTOM_FONTS_FAILED,
  DELETE_CUSTOM_FONTS_START,
  DELETE_CUSTOM_FONTS_SUCCESS,
  DELETE_CUSTOM_FONTS_FAILED,
  FETCH_ALL_CUSTOM_FONTS_START,
  FETCH_ALL_CUSTOM_FONTS_SUCCESS,
  FETCH_ALL_CUSTOM_FONTS_FAILED,
  UPDATE_CUSTOM_FONTS,
  FETCH_ASSET_DETAILS_SUCCESS,
  FETCH_ASSET_DETAILS_START,
} from "../actions/actionTypes";
import { dateTimeDataset } from "../../components/User/UserConfig";
import { sortedUniqueFonts, softDeleteFonts } from "../../_helpers/utils";
import { ANIMATION } from "../../constants/editor";

const initialState = {};

Object.keys(dateTimeDataset).map(key => {
  initialState[key] = [];
  Object.keys(dateTimeDataset[key]).map(elem =>
    initialState[key].push({ name: dateTimeDataset[key][elem], value: elem })
  );
});

const defaultResources = ["timezones", "countries", "states", "fonts"];
defaultResources.forEach(item => {
  if (localStorage.getItem(item)) {
    initialState[item] = JSON.parse(localStorage.getItem(item));
  }
});

export const resourcesReducer = (originalState = initialState, action) => {
  const state = {
    ...originalState,
    type: action.type,
    flashMessage: null,
    status: action?.error?.response?.status ?? null,
  };

  switch (action.type) {
    case RESOURCES_FETCH_SUCCESS:
      if (defaultResources.includes(action.resourceType)) {
        localStorage.setItem([action.resourceType], JSON.stringify(action.entries[action.resourceType]));
      }
      return { ...state, [action.resourceType]: action.entries[action.resourceType] };

    case RESOURCES_FETCH_FAILED:
      return { ...state, resourceError: { [action.resourceType]: action.e } };

    case GET_IP_LOCATION_SUCCESS:
      return { ...state, iplocation: action.data };

    case GET_IP_LOCATION_FAILED:
      return { ...state, iplocationError: action.e };

    case FONTS_FETCH_SUCCESS:
      localStorage.setItem("fonts", JSON.stringify(action.data["fonts"]));
      return { ...state, fonts: action.data["fonts"] };

    case FONTS_FETCH_FAILED:
      return { ...state, resourceError: { fonts: action.error } };

    case UNSPLASH_DOWNLOAD_START:
      return { ...state, loading: false };

    case UNSPLASH_DOWNLOAD_SUCCESS:
      return { ...state, loading: false, ...action.data };

    case UNSPLASH_DOWNLOAD_FAILED:
      console.warn("Failure in triggering unsplash download API!\n", action.error.message);
      return { ...state, loading: false, error: { message: action.error } };

    case FETCH_CUSTOM_FONTS_START:
      return { ...state, custom_fonts: { active: state?.custom_fonts?.active ?? [] }, loading: true };

    case FETCH_CUSTOM_FONTS_SUCCESS:
      if (action.data?.fonts) {
        const storedFonts = JSON.parse(localStorage.getItem("allFonts")) || [];
        const actionFonts = action.data.fonts || [];
        sortedUniqueFonts([...storedFonts, ...actionFonts]);
      }
      return { ...state, custom_fonts: { active: action.data }, loading: false };

    case FETCH_CUSTOM_FONTS_FAILED:
      return { ...state, custom_fonts: { active: [], error: action.error }, loading: false };

    case UPLOAD_CUSTOM_FONTS_START:
      return { ...state, uploaded_custom_fonts: state?.uploaded_custom_fonts ?? false, loading: true };

    case UPLOAD_CUSTOM_FONTS_SUCCESS:
      return { ...state, uploaded_custom_fonts: action.data, loading: false };

    case UPLOAD_CUSTOM_FONTS_FAILED:
      return { ...state, uploaded_custom_fonts: action.error, loading: false };

    case DELETE_CUSTOM_FONTS_START:
      return { ...state, loading: true };

    case DELETE_CUSTOM_FONTS_SUCCESS:
      softDeleteFonts(action?.payload)
      return { ...state, loading: false };

    case DELETE_CUSTOM_FONTS_FAILED:
      return { ...state, loading: false };

    case FETCH_ALL_CUSTOM_FONTS_START:
      return { ...state, custom_fonts: state?.custom_fonts ?? false, loading: true, custom_fonts_loading: true };

    case FETCH_ALL_CUSTOM_FONTS_SUCCESS:
      sortedUniqueFonts(action.data?.fonts ? [...action.data.fonts, ...state.fonts] : state.fonts);
      return { ...state, custom_fonts: { all: action.data }, loading: false, custom_fonts_loading: false };

    case FETCH_ALL_CUSTOM_FONTS_FAILED:
      return { ...state, custom_fonts: action.error, loading: false, custom_fonts_loading: false };

    case UPDATE_CUSTOM_FONTS:
      return { ...state, custom_fonts: { all: action.data }, loading: false };

    case FETCH_ASSET_DETAILS_START:
      return { ...state, assetInfo: null };

    case FETCH_ASSET_DETAILS_SUCCESS:
      const assetInfo =
        action.data.asset_type === ANIMATION
          ? { ...action.data, thumb: action.data.thumb.replace(".png", ".gif") }
          : action.data;

      return { ...state, assetInfo: assetInfo};

    default:
      return state;
  }
};
