import { GOOGLE, VALID_NAME_PATTERN } from "../../constants/user";

export const sidebarOptions = {
  title: "Personal Settings",
  group: [
    {
      name: "General",
      icon_name: "general-settings",
      items: [
        {
          name: "My Details",
          icon_name: "user",
          link: "/users/profile",
          children: ["/users/profile/edit"],
        },
        {
          name: "Connected Accounts",
          icon_name: "connected",
          link: "/users/connected-accounts/",
        },
      ],
    },
    {
      name: "Security",
      icon_name: "security",
      items: [
        {
          name: "Sign In Method",
          icon_name: "out",
          link: "/users/signin-method",
        },
        
        {
          name: "Change Email",
          icon_name: "at-sign",
          link: "/users/change-email",
        },
        {
          provider: [GOOGLE],
          name: "Change Password",
          icon_name: "lock2",
          link: "/users/change-password",
        },
        {
          provider: [GOOGLE],
          name: "Two-Step Verification",
          icon_name: "two-step",
          link: "/users/two-factor-auth",
        },
      ],
    },
    {
      name: "Data Administration",
      icon_name: "database",
      items: [
        {
          name: "Audit Log",
          icon_name: "audit",
          link: "/settings/audit-log",
          // premiumType: "auditLog", //Restricted audit log Modal
        },
        {
          name: "Recycle Bin",
          icon_name: "trash",
          link: "/settings/trash",
        },
      ],
    },
  ],
};

export const detailsPageData = {
  title: "My Details",
  enable_edit: true,
  edit_link: "/users/profile/edit",
  profile_image: true,
  edit_profile_image: true,
  card_data: [
    {
      ref_slug: "email",
    },
    {
      ref_slug: "mobile",
    },
    {
      ref_slug: "phone",
    },
    {
      ref_slug: "time_zone",
    },
    {
      ref_slug: "date_format",
    },
  ],
  field_groups: [
    {
      name: "User Information",
      slug: "user_information",
      fields: [
        {
          name: "Name",
          slug: "name",
          type: "name_field",
          sub_fields: ["firstname", "middlename", "lastname"],
        },
        {
          name: "First Name",
          slug: "firstname",
          type: "text",
          required: true,
          min_length: 1,
          max_length: 255,
          pattern: VALID_NAME_PATTERN,
        },
        {
          name: "Middle Name",
          slug: "middlename",
          type: "text",
          min_length: 1,
          max_length: 255,
          layout: "QUARTER",
          pattern: VALID_NAME_PATTERN,
        },
        {
          name: "Last Name",
          slug: "lastname",
          type: "text",
          min_length: 1,
          max_length: 255,
          required: true,
          layout: "QUARTER",
          pattern: VALID_NAME_PATTERN,
        },
        {
          name: "Email",
          slug: "email",
          type: "email",
          required: true,
        },
        {
          name: "Mobile",
          slug: "mobile",
          type: "phone",
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Phone",
          slug: "phone",
          type: "phone",
          min_length: 1,
          max_length: 255,
        },
      ],
    },
    {
      name: "Address Information",
      slug: "address_information",
      fields: [
        {
          name: "Address Line 1",
          slug: "street",
          type: "text",
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Address Line 2",
          slug: "addressLine2",
          type: "text",
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Country",
          slug: "country_code",
          type: "dropdown",
          datasource: {
            component: "countries",
          },
          required: true,
        },
        {
          name: "State/Province",
          slug: "state",
          type: "dropdown",
          datasource: {
            depends_on: "country_code",
            component: "states",
          },
        },
        {
          name: "City",
          slug: "city",
          type: "text",
          min_length: 1,
          max_length: 255,
        },
        {
          name: "Zip/Postal Code",
          slug: "zip",
          type: "text",
          min_length: 1,
          max_length: 10,
        },
      ],
    },
    {
      name: "Locale Information",
      slug: "locale_information",
      fields: [
        {
          name: "Time Zone",
          slug: "time_zone",
          type: "dropdown",
          datasource: {
            component: "timezones",
          },
        },
        {
          name: "Date Format",
          slug: "date_format",
          type: "dropdown",
          datasource: {
            component: "dateformats",
          },
        },
        {
          name: "Time Format",
          slug: "time_format",
          type: "dropdown",
          datasource: {
            component: "timeformats",
          },
        },
      ],
    },
  ],
};

export const dateTimeDataset = {
  timeformats: {
    "12 Hours": "12 Hours",
    "24 Hours": "24 Hours",
  },

  dateformats: {
    "MM-DD-YYYY": "Month-Day-Year",
    "DD-MM-YYYY": "Day-Month-Year",
  },
};
