import {
  TRASH_FETCH_START,
  TRASH_FETCH_SUCCESS,
  TRASH_FETCH_FAILED,
  TRASH_RESTORE_START,
  TRASH_RESTORE_SUCCESS,
  TRASH_RESTORE_FAILED,
  TRASH_DELETE_START,
  TRASH_DELETE_SUCCESS,
  TRASH_DELETE_FAILED,
} from "../actions/actionTypes";

const initialState = {
  deleted_items: [],
  total: 0,
};

export const trashReducer = (originalState = initialState, action) => {
  const state = {
    ...originalState,
    type: action.type,
    flashMessage: null,
    status: action?.error?.response?.status ?? null,
  };

  switch (action.type) {
    case TRASH_FETCH_START:
      return { ...state, loading: true };

    case TRASH_FETCH_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case TRASH_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: { message: action.error },
        flashMessage: { type: "error", message: "Something went wrong! Please try again later." },
      };

    case TRASH_RESTORE_START:
      return { ...state, loading: true };

    case TRASH_RESTORE_SUCCESS:
      return { ...state, loading: true, ...action.result };

    case TRASH_RESTORE_FAILED:
      return {
        ...state,
        loading: false,
        error: { message: action.error },
        flashMessage: { type: "error", message: "Something went wrong! Please try again later." },
      };

    case TRASH_DELETE_START:
      return { ...state, loading: true };

    case TRASH_DELETE_SUCCESS:
      return { ...state, loading: true, ...action.result };

    case TRASH_DELETE_FAILED:
      return {
        ...state,
        loading: false,
        error: { message: action.error },
        flashMessage: { type: "error", message: "Something went wrong! Please try again later." },
      };

    default:
      return state;
  }
};
