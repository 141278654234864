import axios from "axios";

axios.interceptors.request.use(
  rq => {
    if (localStorage.getItem("access_token") !== undefined)
      rq.headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    return rq;
  },
  err => Promise.reject(err)
);

// auth service
const authRequestManager = axios.create({
  baseURL: process.env.API_URL_BASE + "/auth/v1",
});
authRequestManager.interceptors.request.use(
  rq => {
    if (localStorage.getItem("access_token") !== undefined)
      rq.headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    return rq;
  },
  err => Promise.reject(err)
);

// user service
const userRequestManager = axios.create({
  baseURL: process.env.API_URL_BASE + "/users/v1",
});

userRequestManager.interceptors.request.use(
  rq => {
    if (localStorage.getItem("access_token") !== undefined)
      rq.headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    return rq;
  },
  err => Promise.reject(err)
);

userRequestManager.interceptors.response.use(
  rs => {
    if (rs?.data?.access_token) {
      localStorage.setItem("access_token", rs?.data?.access_token);
    }
    return rs;
  },
  err => Promise.reject(err)
);

// subscription service
const subscriptionRequestManager = axios.create({
  baseURL: process.env.API_URL_BASE + "/subscriptions/v1",
});

subscriptionRequestManager.interceptors.request.use(
  rq => {
    if (localStorage.getItem("access_token") !== undefined)
      rq.headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    return rq;
  },
  err => Promise.reject(err)
);

// template service
const templateRequestManager = axios.create({
  baseURL: process.env.API_URL_BASE + "/templates/v1",
});

templateRequestManager.interceptors.request.use(
  rq => {
    if (localStorage.getItem("access_token") !== undefined)
      rq.headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    return rq;
  },
  err => Promise.reject(err)
);

// resources service
const resourceRequestManager = axios.create({
  baseURL: process.env.API_URL_BASE + "/resources/v1",
});

resourceRequestManager.interceptors.request.use(
  rq => {
    if (localStorage.getItem("access_token") !== undefined)
      rq.headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    return rq;
  },
  err => Promise.reject(err)
);

// document service
const documentRequestManager = axios.create({
  baseURL: process.env.API_URL_BASE + "/documents/v1",
});

documentRequestManager.interceptors.request.use(
  rq => {
    if (localStorage.getItem("access_token") !== undefined)
      rq.headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    return rq;
  },
  err => Promise.reject(err)
);

// search service
const searchRequestManager = axios.create({
  baseURL: process.env.API_URL_BASE + "/search/v1",
});

searchRequestManager.interceptors.request.use(
  rq => {
    if (localStorage.getItem("access_token") !== undefined)
      rq.headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    return rq;
  },
  err => Promise.reject(err)
);

// editor service
const editorRequestManager = axios.create({
  baseURL: process.env.API_URL_BASE + "/resources/v1",
});

editorRequestManager.interceptors.request.use(
  rq => {
    if (localStorage.getItem("access_token") !== undefined)
      rq.headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    return rq;
  },
  err => Promise.reject(err)
);

// audit service
const auditLogRequestManager = axios.create({
  baseURL: process.env.API_URL_BASE + "/audit-log/v1",
});

auditLogRequestManager.interceptors.request.use(
  rq => {
    if (localStorage.getItem("access_token") !== undefined)
      rq.headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    return rq;
  },
  err => Promise.reject(err)
);

// connector service
const connectorRequestManager = axios.create({
  baseURL: process.env.API_URL_BASE + "/connectors/v1",
});

connectorRequestManager.interceptors.request.use(
  rq => {
    if (localStorage.getItem("access_token") !== undefined)
      rq.headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    return rq;
  },
  err => Promise.reject(err)
);

// screenshot service
const screenshotRequestManager = axios.create({
  baseURL: process.env.SCREENSHOT_API,
});

// ai service
const aiRequestManager = axios.create({
  baseURL: process.env.API_URL_BASE + "/ai/v1",
});

aiRequestManager.interceptors.request.use(
  rq => {
    if (localStorage.getItem("access_token") !== undefined)
      rq.headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    return rq;
  },
  err => Promise.reject(err)
);

// global
const axiosApp = axios.create({
  baseURL: process.env.API_URL_BASE,
});

axiosApp.interceptors.request.use(
  rq => {
    if (localStorage.getItem("access_token") !== undefined)
      rq.headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    return rq;
  },
  err => Promise.reject(err)
);

axiosApp.interceptors.response.use(
  rs => {
    if (rs?.data?.access_token) {
      localStorage.setItem("access_token", rs?.data?.access_token);
    }
    return rs;
  },
  err => Promise.reject(err)
);

export default axiosApp;

export {
  authRequestManager,
  userRequestManager,
  templateRequestManager,
  resourceRequestManager,
  documentRequestManager,
  searchRequestManager,
  editorRequestManager,
  auditLogRequestManager,
  subscriptionRequestManager,
  connectorRequestManager,
  screenshotRequestManager,
  aiRequestManager,
};
