// Template Group
export const TEMPLATE_GROUP_SOCIAL_GRAPHIC = "Social Graphic";
export const TEMPLATE_GROUP_WEB_BANNER = "Web Banner";
export const TEMPLATE_GROUP_ADVERTISEMENT = "Advertisement";
export const TEMPLATE_GROUP_VIDEO = "Video";

//Template type
export const TEMPLATE_TYPE_PRESENTATION = "presentation";
export const TEMPLATE_TYPE_INFOGRAPHIC = "infographic";

// Selected value in Modal
export const TEMPLATE_GROUP_VALUE_SOCIAL_GRAPHIC = "socialGraphic";
export const TEMPLATE_GROUP_VALUE_WEB_BANNER = "webBanner";
export const TEMPLATE_GROUP_VALUE_COMMON = "common";
export const TEMPLATE_GROUP_VALUE_ADVERTISEMENT = "advertisement";
export const TEMPLATE_GROUP_VALUE_VIDEO = "video";

// page type custom
export const PAGETYPE_CUSTOM_LABEL = "Custom";
export const PAGETYPE_CUSTOM_VALUE = "custom";
export const PAGETYPE_STANDARD_VALUE = "standard";

export const SPINNER_FIELDS_META = {
    label: ["Width", "Height"],
    px: {
        maxHeight: 5000,
        minHeight: 10,
        maxWidth: 3840,
        minWidth: 10,
    },
    in: {
        maxHeight: 69.44,
        minHeight: 0.14,
        maxWidth: 53.33,
        minWidth: 0.14,
    },
    cm: {
        maxHeight: 176.39,
        minHeight: 0.35,
        maxWidth: 135.47,
        minWidth: 0.35,
    },
    mm: {
        maxHeight: 1763.89,
        minHeight: 3.53,
        maxWidth: 1354.67,
        minWidth: 3.53,
    }
};

export const SPINNER_FIELDS_META_INFOGRAPHIC = {
    label: ["Width", "Height"],
    px: {
        maxHeight: 5000,
        minHeight: 80,
        maxWidth: 3840,
        minWidth: 10,
    },
    in: {
        maxHeight: 69.44,
        minHeight: 1.11,
        maxWidth: 53.33,
        minWidth: 0.14,
    },
    cm: {
        maxHeight: 176.39,
        minHeight: 2.82,
        maxWidth: 135.47,
        minWidth: 0.35,
    },
    mm: {
        maxHeight: 1763.89,
        minHeight: 28.22,
        maxWidth: 1354.67,
        minWidth: 3.53,
    }
};

export const RESIZER_TYPE = [{
    name: "pageSizeType",
    text: "Standard",
    value: "standard",
    type: "radio",
    active: false
},
{
    name: "pageSizeType",
    text: "Custom",
    value: "custom",
    type: "radio",
    active: false
}]

export const ORIENTATION_TYPE = [
    {
        name: "pageSizeOrientationType",
        text: "Portrait",
        value: "portrait",
        type: "radio",
        active: false
    },
    {
        name: "pageSizeOrientationType",
        text: "Landscape",
        value: "landscape",
        type: "radio",
        active: false
    },
];

export const LENGTH_UNITS_CONVERSION_FACTORS = [
    { name: "px", factor: 1 },
    { name: "in", factor: 1 / 72 },
    { name: "cm", factor: (1 / 72) * 2.54 },
    { name: "mm", factor: (1 / 72) * 25.4 }
];

