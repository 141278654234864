import {
  RESOURCES_FETCH_START,
  RESOURCES_FETCH_SUCCESS,
  RESOURCES_FETCH_FAILED,
  GET_IP_LOCATION_START,
  GET_IP_LOCATION_SUCCESS,
  GET_IP_LOCATION_FAILED,
  FONTS_FETCH_START,
  FONTS_FETCH_SUCCESS,
  FONTS_FETCH_FAILED,
  UNSPLASH_DOWNLOAD_START,
  UNSPLASH_DOWNLOAD_SUCCESS,
  UNSPLASH_DOWNLOAD_FAILED,
  FETCH_CUSTOM_FONTS_START,
  FETCH_CUSTOM_FONTS_SUCCESS,
  FETCH_CUSTOM_FONTS_FAILED,
  UPLOAD_CUSTOM_FONTS_START,
  UPLOAD_CUSTOM_FONTS_SUCCESS,
  UPLOAD_CUSTOM_FONTS_FAILED,
  DELETE_CUSTOM_FONTS_START,
  DELETE_CUSTOM_FONTS_SUCCESS,
  DELETE_CUSTOM_FONTS_FAILED,
  FETCH_ALL_CUSTOM_FONTS_START,
  FETCH_ALL_CUSTOM_FONTS_SUCCESS,
  FETCH_ALL_CUSTOM_FONTS_FAILED,
  UPDATE_CUSTOM_FONTS,
  FETCH_ASSET_DETAILS_START,
  FETCH_ASSET_DETAILS_FAILED,
  FETCH_ASSET_DETAILS_SUCCESS,
} from "./actionTypes";
import { resourceRequestManager as API } from "../../_helpers/requestManager";

const getResourceStart = () => {
  return { type: RESOURCES_FETCH_START };
};

const getResourceFetched = (entries, resourceType) => {
  return { type: RESOURCES_FETCH_SUCCESS, entries, resourceType };
};

const getResourceFailed = e => {
  return { type: RESOURCES_FETCH_FAILED, e };
};

const getipLocationStart = () => {
  return { type: GET_IP_LOCATION_START };
};

const getipLocationSuccess = data => {
  return { type: GET_IP_LOCATION_SUCCESS, data };
};

const getipLocationFailed = e => {
  return { type: GET_IP_LOCATION_FAILED, e };
};

export const fetchFontAssetsStart = () => {
  return { type: FONTS_FETCH_START };
};

export const fetchFontAssetsSuccess = data => {
  return { type: FONTS_FETCH_SUCCESS, data };
};

export const fetchFontAssetsFailed = error => {
  return { type: FONTS_FETCH_FAILED, error };
};

const triggerUnsplashDownloadStart = () => {
  return { type: UNSPLASH_DOWNLOAD_START };
};

const triggerUnsplashDownloadSuccess = data => {
  return { type: UNSPLASH_DOWNLOAD_SUCCESS, data };
};

const triggerUnsplashDownloadFailed = error => {
  return { type: UNSPLASH_DOWNLOAD_FAILED, error };
};

const fetchCustomFontsStart = () => {
  return { type: FETCH_CUSTOM_FONTS_START };
};

const fetchCustomFontsFailed = error => {
  return { type: FETCH_CUSTOM_FONTS_FAILED, error };
};

const fetchCustomFontsSuccess = data => {
  return { type: FETCH_CUSTOM_FONTS_SUCCESS, data };
};

const uploadCustomFontsStart = () => {
  return { type: UPLOAD_CUSTOM_FONTS_START };
};

const uploadCustomFontsFailed = error => {
  return { type: UPLOAD_CUSTOM_FONTS_FAILED, error };
};

const uploadCustomFontsSuccess = data => {
  return { type: UPLOAD_CUSTOM_FONTS_SUCCESS, data };
};

const deleteCustomFontsStart = () => {
  return { type: DELETE_CUSTOM_FONTS_START };
};

const deleteCustomFontsFailed = error => {
  return { type: DELETE_CUSTOM_FONTS_FAILED, error };
};

const deleteCustomFontsSuccess = (data, payload) => {
  return { type: DELETE_CUSTOM_FONTS_SUCCESS, data, payload };
};

const fetchAllCustomFontsStart = () => {
  return { type: FETCH_ALL_CUSTOM_FONTS_START };
};

const fetchAllCustomFontsFailed = error => {
  return { type: FETCH_ALL_CUSTOM_FONTS_FAILED, error };
};

const fetchAllCustomFontsSuccess = data => {
  return { type: FETCH_ALL_CUSTOM_FONTS_SUCCESS, data };
};

const fetchAssetInfoStart = () => {
  return { type: FETCH_ASSET_DETAILS_START };
};

const fetchAssetInfoFailed = error => {
  return { type: FETCH_ASSET_DETAILS_FAILED, error };
};

const fetchAssetInfoSuccess = data => {
  return { type: FETCH_ASSET_DETAILS_SUCCESS, data };
};

export const getResourceEntries = resourceType => {
  return dispatch => {
    dispatch(getResourceStart());
    return API.get(`/${resourceType}`, {
      headers: {
        "X-Auth": "NoAuth",
      },
    })
      .then(r => {
        dispatch(getResourceFetched(r.data, resourceType));
      })
      .catch(e => {
        dispatch(getResourceFailed(e));
      });
  };
};

export const getipLocation = () => {
  return dispatch => {
    dispatch(getipLocationStart());
    return API.get(`/iplocation`, {
      headers: {
        "X-Auth": "NoAuth",
      },
    })
      .then(r => {
        dispatch(getipLocationSuccess(r.data));
      })
      .catch(e => {
        dispatch(getipLocationFailed(e));
      });
  };
};

export const fetchFontAssets = () => {
  return dispatch => {
    dispatch(fetchFontAssetsStart());
    return API.get(`/fonts?page=1&limit=300`, {
      headers: {
        "X-Auth": "NoAuth",
      },
    })
      .then(r => {
        dispatch(fetchFontAssetsSuccess(r.data));
      })
      .catch(e => {
        dispatch(fetchFontAssetsFailed(e));
      });
  };
};

export const triggerUnsplashDownload = photoId => {
  return dispatch => {
    dispatch(triggerUnsplashDownloadStart());
    return API.get(`/photos/${photoId}/download`)
      .then(r => {
        dispatch(triggerUnsplashDownloadSuccess(r.data));
      })
      .catch(e => {
        dispatch(triggerUnsplashDownloadFailed(e));
      });
  };
};

export const fetchCustomFonts = payload => {
  let page = payload?.page ?? 1;
  let limit = payload?.limit ?? 25;
  const endPoint = `/uploader/company-fonts?page=${page}&limit=${limit}`;
  return dispatch => {
    dispatch(fetchCustomFontsStart());
    return API
      .get(endPoint)
      .then(r => dispatch(fetchCustomFontsSuccess(r.data)))
      .catch(e => dispatch(fetchCustomFontsFailed(e)));
  };
};

export const uploadCustomFonts = file => {
  return dispatch => {
    dispatch(uploadCustomFontsStart());
    return API
      .post(`/uploader/fonts`, file, { headers: { "Content-Type": undefined } })
      .then(r => dispatch(uploadCustomFontsSuccess(r.data)))
      .catch(e => dispatch(uploadCustomFontsFailed(e)));
  };
};

export const deleteCustomFonts = payload => {
  return dispatch => {
    dispatch(deleteCustomFontsStart());
    return API
      .delete(`/uploader/fonts`, { data: payload })
      .then(r => dispatch(deleteCustomFontsSuccess(r.data, payload)))
      .catch(e => dispatch(deleteCustomFontsFailed(e)));
  };
};

export const fetchAllCustomFonts = companyId => {
  const endPoint = `/uploader/fonts?company_id=${companyId}`;
  return dispatch => {
    dispatch(fetchAllCustomFontsStart());
    return API
      .get(endPoint, {
        headers: {
          "X-Auth": "NoAuth",
        },
      })
      .then(r => dispatch(fetchAllCustomFontsSuccess(r.data)))
      .catch(e => dispatch(fetchAllCustomFontsFailed(e)));
  };
};

export const updateCustomFonts = fonts => {
  return {
    type: UPDATE_CUSTOM_FONTS,
    data: { fonts, total: fonts?.length ?? 0 },
  };
};

export const getAssetDetails = assetId => {
  return dispatch => {
    dispatch(fetchAssetInfoStart());
    return API.get(`/${assetId}/details`, {
      headers: {
        "X-Auth": "NoAuth",
      },
    })
      .then(r => {
        dispatch(fetchAssetInfoSuccess(r.data.data));
      })
      .catch(e => {
        dispatch(fetchAssetInfoFailed(e));
      });
  };
};