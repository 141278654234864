import {
  FETCH_SUBSCRIPTION_PLAN_SUCCESS,
  FETCH_SUBSCRIPTION_PRICE_DETAILS_START,
  FETCH_SUBSCRIPTION_PRICE_DETAILS_SUCCESS,
  FETCH_SUBSCRIPTION_DETAILS_SUCCESS,
  FETCH_SUBSCRIPTION_DETAILS_START,
  FETCH_SUBSCRIPTION_DETAILS_FAILED,
  FETCH_SUBSCRIPTION_CURRENCY_SUCCESS,
  CANCEL_SUBSCRIPTION_SUCCESS,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  GENERATE_PAYLINK_SUCCESS,
  UPDATE_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_INVOICES_START,
  FETCH_SUBSCRIPTION_INVOICES_SUCCESS,
  FETCH_SUBSCRIPTION_INVOICES_FAILED,
  FETCH_BILLING_PRODUCTS_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  deleted_items: [],
  total: 0,
};

export const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        subscriptionInfo: {
          ...state.subscriptionInfo,
          plan: action.planInfo?.subscription_plan,
          currency: action.planInfo?.subscription_currency,
          payLink: null,
        },
      };

    case FETCH_SUBSCRIPTION_PRICE_DETAILS_START:
      return {
        ...state,
        loading: true,
        subscriptionInfo: { ...state.subscriptionInfo, priceDetails: null },
      };

    case FETCH_SUBSCRIPTION_PRICE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionInfo: {
          ...state.subscriptionInfo,
          [action.isProduct ? "productPriceInfo" : "priceDetails"]: action.priceInfo,
        },
      };

    case FETCH_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionInfo: { ...state.subscriptionInfo, currentSubscriptionInfo: action.info },
      };

    case FETCH_SUBSCRIPTION_DETAILS_START:
      return { ...state, loading: true, isSubscriptionCancel: null, isPaymentMethodChange: null, payLink: null };

    case FETCH_SUBSCRIPTION_DETAILS_FAILED:
      return { ...state, loading: false };

    case FETCH_SUBSCRIPTION_CURRENCY_SUCCESS:
      return {
        ...state,
        subscriptionInfo: {
          ...state.subscriptionInfo,
          subscription_currency: action.currencyList?.subscription_currency,
        },
      };

    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionInfo: {
          ...state.subscriptionInfo,
          isSubscriptionCancel: true,
        },
      };

    case UPDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        subscriptionInfo: {
          ...state.subscriptionInfo,
          isPaymentMethodChange: true,
        },
      };

    case GENERATE_PAYLINK_SUCCESS:
      return {
        ...state,
        subscriptionInfo: {
          ...state.subscriptionInfo,
          payLink: action.data.pay_link,
        },
      };

    case UPDATE_SUBSCRIPTION_SUCCESS:
      return { ...state, loading: false, subscriptionInfo: null, reloadSubscription: true };

    case FETCH_SUBSCRIPTION_INVOICES_START:
      return { ...state, loading: true, invoiceInfo: null };

    case FETCH_SUBSCRIPTION_INVOICES_SUCCESS:
      return { ...state, loading: false, invoiceInfo: action.data };

    case FETCH_SUBSCRIPTION_INVOICES_FAILED:
      return { ...state, loading: false, invoiceInfo: null };

    case FETCH_BILLING_PRODUCTS_SUCCESS:
      return {
        ...state,
        subscriptionInfo: {
          ...state.subscriptionInfo,
          products: action.data.products,
        },
      };

    default:
      return state;
  }
};
