import loadable from "@loadable/component";
import * as Sentry from "@sentry/browser";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import "jquery/dist/jquery.min";
import "bootstrap/dist/js/bootstrap.min";
import TopLoadingBar from "./components/ui/topLoadingBar";
import App from "./App";

// if (process.env.NODE_ENV === "production") {
//   Sentry.init({
//     dsn: process.env.ERROR_TRACKER,
//     release: "dhp@" + process.env.npm_package_version,
//   });
// }

// TODO: warmup initial store data
const initialData = {};
const store = configureStore(initialData);

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <TopLoadingBar />
    <App />
  </Provider>
);

if (module.hot) {
  module.hot.accept();
  module.hot.accept([], () => {
    root.render(App);
  });
}
