import { useContext, useEffect, useState } from "react";

import { CONN_DEFAULT, CONN_DOC_SAVE, DOCUMENT_SAVE_PATH } from "../constants/socket";
import { AppContext } from "../contexts";

export const useConnectSocket = (setSocketConnectionInfo, setDocumentWS, setRestartSave) => {
  const [ws, setWs] = useState(null);
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    let newSocket = null;
    let docSaveSocketConnection = null;

    const startWebsocket = () => {
      setIsOffline(false);
      newSocket = new WebSocket(process.env.WEB_SOCKET_URL);
      setWs(newSocket);

      newSocket.onopen = () => {
        setSocketConnectionInfo(newSocket);
        console.log("WebSocket open");
      };

      newSocket.onclose = () => {
        console.log("WebSocket closed");
        if (!isOffline) {
          setTimeout(startWebsocket, 2000);
        }
      };

      newSocket.onerror = err => {
        console.log("WebSocket error:", err);
        newSocket.close();
      };
    };

    /**
     * For document save
     */
    const startWebSocketForDocumentSave = () => {
      // Set socket connection for Document Save
      docSaveSocketConnection = new WebSocket(`${process.env.SOCKET_IO_URL}${DOCUMENT_SAVE_PATH}`);

      docSaveSocketConnection.onopen = () => {
        setDocumentWS(docSaveSocketConnection);
        setRestartSave(true);
        console.log("Document Save WebSocket open");
      };

      docSaveSocketConnection.onclose = () => {
        console.log("Document Save WebSocket closed");
        if (!isOffline) {
          setTimeout(startWebSocketForDocumentSave, 2000);
        }
      };

      docSaveSocketConnection.onerror = err => {
        console.log("Document Save WebSocket error:", err);
        docSaveSocketConnection.close();
      };
    };

    const handleOffline = () => {
      console.log("Offline event detected");
      setIsOffline(true);
      if (newSocket) {
        newSocket.close();
      }

      if (docSaveSocketConnection) {
        docSaveSocketConnection.close();
      }

      setWs(null);
      setSocketConnectionInfo(null);
      setDocumentWS(null);
    };

    const handleOnline = () => {
      console.log("Online event detected");
      setIsOffline(false);
      if (isOffline) {
        setTimeout((startWebsocket, startWebSocketForDocumentSave), 5000);
      }
    };

    startWebsocket();
    startWebSocketForDocumentSave();

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      if (newSocket) {
        newSocket.close();
      }

      if (docSaveSocketConnection) {
        docSaveSocketConnection.close();
      }

      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
      setWs(null);
      setSocketConnectionInfo(null);
      setDocumentWS(null);
    };
  }, []);

  return ws;
};

export const useSocket = (connType = CONN_DEFAULT) => {
  const { socketConnectionInfo, documentWS } = useContext(AppContext);

  if (connType === CONN_DOC_SAVE) return documentWS;
  return socketConnectionInfo;
};
