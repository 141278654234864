import React from "react";
import { Route, Redirect } from "react-router-dom";
import { setTargetRedirectionEndpoint } from "./utils";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  let hashkey;
  if (!localStorage.getItem("access_token")) {
    if (!localStorage.getItem("isLoggout")) {
      /**
       * Below condition to check is base url equal to target url
       * - if yes then dont set target
       */
      if (window.location.href.replace(/\/$/, "") !== process.env.BASE_URL) {
        hashkey = setTargetRedirectionEndpoint(`${window.location.pathname}${window.location.search}`);
      }
    } else localStorage.removeItem("isLoggout");
  }
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem("access_token") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: `/signin`, state: { from: props.location }, search: hashkey && `target=${hashkey}` }}
          />
        )
      }
    />
  );
};
