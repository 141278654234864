import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import {
  USER_LOGIN_START,
  USER_SIGNUP_START,
  SIGNUP_VERIFICATION_START,
  COMPANY_FETCH_START,
  GLOBAL_SEARCH_START,
  FORGOT_PASSWORD_START,
  RESET_PASSWORD_START,
  ASSET_FETCH_START,
  ASSET_FILTER_START,
  VIDEO_FETCH_START,
  UPLOAD_ASSET_FETCH_START,
  UPLOAD_ASSET_DELETE_START,
  STOCK_PHOTO_FETCH_START,
  DOCUMENT_COMMENTS_START,
  GET_USER_LIST_START,
  INSERT_COMMENTS_START,
  UPDATE_COMMENT_START,
  DELETE_COMMENT_START,
  APPLY_LIKE_START,
  FETCH_ALL_CUSTOM_FONTS_START,
} from "../../store/actions/actionTypes";

const TopLoadingBar = () => {
  const exceptions = [
    USER_LOGIN_START,
    USER_SIGNUP_START,
    SIGNUP_VERIFICATION_START,
    FORGOT_PASSWORD_START,
    RESET_PASSWORD_START,
    COMPANY_FETCH_START,
    GLOBAL_SEARCH_START,
    ASSET_FETCH_START,
    ASSET_FILTER_START,
    VIDEO_FETCH_START,
    UPLOAD_ASSET_FETCH_START,
    UPLOAD_ASSET_DELETE_START,
    STOCK_PHOTO_FETCH_START,
    DOCUMENT_COMMENTS_START,
    GET_USER_LIST_START,
    INSERT_COMMENTS_START,
    UPDATE_COMMENT_START,
    DELETE_COMMENT_START,
    APPLY_LIKE_START,
    FETCH_ALL_CUSTOM_FONTS_START
  ];
  const [visible, setVisible] = useState(false);
  const allStates = useSelector(({ loadingBar, ...state }) => state);
  let isEditor = window.location.pathname.includes("/editor/");
  const dispatch = useDispatch();

  const handleChange = state => {
    let isLoading = isEditor ? false : Object.keys(state).some(field => state[field].loading && !exceptions.includes(state[field].type));
    if (isLoading != visible) setVisible(isLoading);
  };

  useEffect(() => {
    handleChange(allStates);
  }, [allStates]);

  useEffect(() => {
    if (visible) dispatch(showLoading());
    if (!visible) dispatch(hideLoading());
  }, [visible]);

  return <></>;
};

export default TopLoadingBar;
