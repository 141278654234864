import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  const detectScrollY = () => {
    // set class for pages where document.body has got vertical scroll bar
    setTimeout(() => {
      document.body.classList[document.body.scrollHeight > document.body.clientHeight ? "add" : "remove"](
        "body-scroll"
      );
    }, 300);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    detectScrollY();
  });

  return null;
};

export default ScrollToTop;
