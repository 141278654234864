import { GLOBAL_SEARCH_START, GLOBAL_SEARCH_SUCCESS, GLOBAL_SEARCH_FAILED } from "../actions/actionTypes";

const initialState = {
  components: {},
};

export const searchReducer = (originalState = initialState, action) => {
  const state = {
    ...originalState,
    loading: false,
    type: action.type,
    flashMessage: null,
    status: action?.error?.response?.status ?? null,
  };

  switch (action.type) {
    case GLOBAL_SEARCH_START:
      return { ...state, loading: true, components: {} };

    case GLOBAL_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        components: action.result?.components || {},
      };

    case GLOBAL_SEARCH_FAILED:
      return {
        ...state,
        loading: false,
        error: { message: action.error },
        flashMessage: { type: "error", message: "Something went wrong! Please try again later." },
        components: {},
      };

    default:
      return state;
  }
};
