// DOCUMENT PERMISSION
export const DOCUMENT_PERMISSION_OWNER = "OWNER";
export const DOCUMENT_PERMISSION_FULL = "FULL";
export const DOCUMENT_PERMISSION_RW = "RW";
export const DOCUMENT_PERMISSION_RO = "RO";

// Document Config Keys
export const DEFAULT_VIEW_KEY = "records:grid:views:default";
export const DEFAULT_VIEW_SORT_KEY = "records:grid:views:*:sort";
export const DEFAULT_LIST_VIEW_KEY = "records:list:views:default";

export const DOCUMENT_CREATE_STATUS = "access denied";
export const TARGET_COMPANY_TRANSFER_STATUS = "Target Company Access denied";
export const TARGET_COMPANY_TRANSFER_INFO =
  "The target company has already reached the maximum document count allowed in the Free Edition.";
export const DOCUMENT_NOT_FOUND = "Document not found";
