import React from "react";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";

let style = Object.assign({}, global);

const Image = ({ PngImage, WebpImage, ...props }) => {
  return (
    <picture className={style["img-fluid"]}>
      <source className={style["img-fluid"]} srcSet={WebpImage} type="image/webp" />
      <source  className={style["img-fluid"]}srcSet={PngImage} type="image/png" />
      <img className={style["img-fluid"]} src={PngImage} alt={props.altText || "DocHipo"} draggable="false"/>
    </picture>
  );
};

Image.propTypes = {
  PngImage: PropTypes.string.isRequired,
  WebpImage: PropTypes.string.isRequired,
  altText: PropTypes.string,
};

export default Image;
