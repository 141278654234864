import {
  FETCH_TEXT_TO_IMAGE_LIST_START,
  FETCH_TEXT_TO_IMAGE_LIST_SUCCESS,
  FETCH_TEXT_TO_IMAGE_LIST_FAILED,
  FETCH_TEXT_TO_IMAGE_STYLE_START,
  FETCH_TEXT_TO_IMAGE_STYLE_SUCCESS,
  FETCH_TEXT_TO_IMAGE_STYLE_FAILED,
  CREATE_TEXT_TO_IMAGE_COLLECTION_START,
  CREATE_TEXT_TO_IMAGE_COLLECTION_SUCCESS,
  CREATE_TEXT_TO_IMAGE_COLLECTION_FAILED,
  FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_START,
  FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_SUCCESS,
  FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_FAILED,
  UPDATE_TEXT_TO_IMAGE_COLLECTION_START,
  UPDATE_TEXT_TO_IMAGE_COLLECTION_SUCCESS,
  UPDATE_TEXT_TO_IMAGE_COLLECTION_FAILED,
  DELETE_TEXT_TO_IMAGE_COLLECTION_START,
  DELETE_TEXT_TO_IMAGE_COLLECTION_SUCCESS,
  DELETE_TEXT_TO_IMAGE_COLLECTION_FAILED,
  CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_START,
  CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_SUCCESS,
  CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_FAILED,
  UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_START,
  UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_SUCCESS,
  UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_FAILED,
  FETCH_AI_WRITER_TONE_START,
  FETCH_AI_WRITER_TONE_SUCCESS,
  FETCH_AI_WRITER_TONE_FAILED,
  CREATE_AI_WRITER_TEXT_COMPLETION_START,
  CREATE_AI_WRITER_TEXT_COMPLETION_SUCCESS,
  CREATE_AI_WRITER_TEXT_COMPLETION_FAILED,
  UPDATE_AI_WRITER_TEXT_COMPLETION_START,
  UPDATE_AI_WRITER_TEXT_COMPLETION_SUCCESS,
  UPDATE_AI_WRITER_TEXT_COMPLETION_FAILED,
  FETCH_AI_WRITER_TEXT_COMPLETION_LIST_START,
  FETCH_AI_WRITER_TEXT_COMPLETION_LIST_SUCCESS,
  FETCH_AI_WRITER_TEXT_COMPLETION_LIST_FAILED,
  FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_START,
  FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_SUCCESS,
  FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_FAILED,
  DELETE_AI_WRITER_TEXT_COMPLETION_START,
  DELETE_AI_WRITER_TEXT_COMPLETION_SUCCESS,
  DELETE_AI_WRITER_TEXT_COMPLETION_FAILED,
  FETCH_AI_WRITER_EXTEND_START,
  FETCH_AI_WRITER_EXTEND_SUCCESS,
  FETCH_AI_WRITER_EXTEND_FAILED,
  AI_TRANSLATE_START,
  AI_TRANSLATE_SUCCESS,
  AI_TRANSLATE_FAILED,
  FETCH_AI_TRANSLATE_USAGE_START,
  FETCH_AI_TRANSLATE_USAGE_SUCCESS,
  FETCH_AI_TRANSLATE_USAGE_FAILED,
  FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_START,
  FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_SUCCESS,
  FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_FAILED,
} from "../actions/actionTypes";

const initialState = {};
const errorTemplate = {
  text2img: "You have consumed the maximum number of AI generated images for the current month.",
  aiwriter: "You have consumed the maximum number of AI writer usage for the current month.",
  default: "The service is currently unavailable. Please try again later.",
};

const getErrorMessage = (type, data) => {
  return data === "Rate limit exceeded" ? errorTemplate[type] : errorTemplate.default;
};

export const aiReducer = (originalState = initialState, action) => {
  const state = {
    ...originalState,
    type: action.type,
    flashMessage: null,
    status: action?.error?.response?.status ?? null,
    errorMessage: null,
  };

  switch (action.type) {
    case FETCH_TEXT_TO_IMAGE_LIST_START:
      return {
        ...state,
        loading: false,
        loadingList: true,
        collection: null,
        etaCollection: null,
        updatedCollection: null,
        etaUpdatedCollection: null,
        collectionDetail: null,
      };

    case FETCH_TEXT_TO_IMAGE_LIST_SUCCESS:
      return { ...state, loading: false, loadingList: false, collectionList: action.result };

    case FETCH_TEXT_TO_IMAGE_LIST_FAILED:
      return { ...state, loading: false, loadingList: false, error: { message: action.error } };

    case FETCH_TEXT_TO_IMAGE_STYLE_START:
      return { ...state, loading: false };

    case FETCH_TEXT_TO_IMAGE_STYLE_SUCCESS:
      return { ...state, loading: false, style: action.result };

    case FETCH_TEXT_TO_IMAGE_STYLE_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case CREATE_TEXT_TO_IMAGE_COLLECTION_START:
      return { ...state, loading: false, inProgress: true };

    case CREATE_TEXT_TO_IMAGE_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        inProgress: action?.result?.eta ? true : false,
        collection: action.result,
      };

    case CREATE_TEXT_TO_IMAGE_COLLECTION_FAILED:
      return {
        ...state,
        loading: false,
        inProgress: false,
        error: { message: action.error },
        errorMessage: getErrorMessage("text2img", action?.error?.response?.data?.message),
      };

    case FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_START:
      return { ...state, loading: false, loadingDetail: true };

    case FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_SUCCESS:
      return { ...state, loading: false, loadingDetail: false, collectionDetail: action.result };

    case FETCH_TEXT_TO_IMAGE_COLLECTION_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        loadingDetail: false,
        error: { message: action.error },
        errorMessage: getErrorMessage("text2img", action?.error?.response?.data?.message),
      };

    case UPDATE_TEXT_TO_IMAGE_COLLECTION_START:
      return { ...state, loading: false, inProgress: true };

    case UPDATE_TEXT_TO_IMAGE_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        inProgress: action?.result?.eta ? true : false,
        updatedCollection: action.result,
      };

    case UPDATE_TEXT_TO_IMAGE_COLLECTION_FAILED:
      return {
        ...state,
        loading: false,
        inProgress: false,
        error: { message: action.error },
        errorMessage: getErrorMessage("text2img", action?.error?.response?.data?.message),
      };

    case DELETE_TEXT_TO_IMAGE_COLLECTION_START:
      return { ...state, loading: false };

    case DELETE_TEXT_TO_IMAGE_COLLECTION_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DELETE_TEXT_TO_IMAGE_COLLECTION_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_START:
      return { ...state, loading: false, inProgress: true };

    case CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_SUCCESS:
      return { ...state, loading: false, inProgress: false, etaCollection: action.result };

    case CREATE_TEXT_TO_IMAGE_ETA_COLLECTION_FAILED:
      return {
        ...state,
        loading: false,
        inProgress: false,
        error: { message: action.error },
        errorMessage: getErrorMessage("text2img", action?.error?.response?.data?.message),
      };

    case UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_START:
      return { ...state, loading: false, inProgress: true };

    case UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_SUCCESS:
      return { ...state, loading: false, inProgress: false, etaUpdatedCollection: action.result };

    case UPDATE_TEXT_TO_IMAGE_ETA_COLLECTION_FAILED:
      return {
        ...state,
        loading: false,
        inProgress: false,
        error: { message: action.error },
        errorMessage: getErrorMessage("text2img", action?.error?.response?.data?.message),
      };

    //AI Writer

    case FETCH_AI_WRITER_TONE_START:
      return { ...state, loading: false };

    case FETCH_AI_WRITER_TONE_SUCCESS:
      return { ...state, loading: false, tone: action.result.data };

    case FETCH_AI_WRITER_TONE_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case CREATE_AI_WRITER_TEXT_COMPLETION_START:
      return { ...state, loading: false, inProgress: true };

    case CREATE_AI_WRITER_TEXT_COMPLETION_SUCCESS:
      return { ...state, loading: false, inProgress: false, textCompletion: action.result };

    case CREATE_AI_WRITER_TEXT_COMPLETION_FAILED:
      return {
        ...state,
        loading: false,
        inProgress: false,
        error: { message: action.error },
        errorMessage: getErrorMessage("aiwriter", action?.error?.response?.data?.message),
      };

    case UPDATE_AI_WRITER_TEXT_COMPLETION_START:
      return { ...state, loading: false, inProgress: true };

    case UPDATE_AI_WRITER_TEXT_COMPLETION_SUCCESS:
      return { ...state, loading: false, inProgress: false, updatedTextCompletion: action.result };

    case UPDATE_AI_WRITER_TEXT_COMPLETION_FAILED:
      return {
        ...state,
        loading: false,
        inProgress: false,
        error: { message: action.error },
        errorMessage: getErrorMessage("aiwriter", action?.error?.response?.data?.message),
      };

    case FETCH_AI_WRITER_TEXT_COMPLETION_LIST_START:
      return {
        ...state,
        loading: false,
        loadingList: true,
        textCompletionDetails: null,
        updatedTextCompletion: null,
        textCompletion: null,
      };

    case FETCH_AI_WRITER_TEXT_COMPLETION_LIST_SUCCESS:
      return { ...state, loading: false, loadingList: false, textCompletionList: action.result };

    case FETCH_AI_WRITER_TEXT_COMPLETION_LIST_FAILED:
      return { ...state, loading: false, loadingList: false, error: { message: action.error } };

    case FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_START:
      return { ...state, loading: false, loadingDetail: true };

    case FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_SUCCESS:
      return { ...state, loading: false, loadingDetail: false, textCompletionDetails: action.result };

    case FETCH_AI_WRITER_TEXT_COMPLETION_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        loadingDetail: false,
        error: { message: action.error },
        errorMessage: getErrorMessage("aiwriter", action?.error?.response?.data?.message),
      };

    case DELETE_AI_WRITER_TEXT_COMPLETION_START:
      return { ...state, loading: false };

    case DELETE_AI_WRITER_TEXT_COMPLETION_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DELETE_AI_WRITER_TEXT_COMPLETION_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case FETCH_AI_WRITER_EXTEND_START:
      return { ...state, loading: false };

    case FETCH_AI_WRITER_EXTEND_SUCCESS:
      return { ...state, loading: false, textExtended: action.result };

    case FETCH_AI_WRITER_EXTEND_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_START:
      return { ...state, loading: false };

    case FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_SUCCESS:
      return { ...state, loading: false, languages: action.result };

    case FETCH_AI_TRANSLATE_SUPPORTED_LANGUAGES_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case FETCH_AI_TRANSLATE_USAGE_START:
      return { ...state, loading: false };

    case FETCH_AI_TRANSLATE_USAGE_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case FETCH_AI_TRANSLATE_USAGE_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case AI_TRANSLATE_START:
      return { ...state, loading: false };

    case AI_TRANSLATE_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case AI_TRANSLATE_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    default:
      return state;
  }
};
