import {
  TEMPLATE_GROUP_START,
  TEMPLATE_GROUP_SUCCESS,
  TEMPLATE_GROUP_FAILED,
  TEMPLATE_PAGE_SIZE_START,
  TEMPLATE_PAGE_SIZE_SUCCESS,
  TEMPLATE_PAGE_SIZE_FAILED,
  TEMPLATE_DETAILS_FETCH_START,
  TEMPLATE_DETAILS_FETCH_SUCCESS,
  TEMPLATE_DETAILS_FETCH_FAILED,
  TEMPLATES_START,
  TEMPLATES_SUCCESS,
  TEMPLATES_FAILED,
  TEMPLATE_TYPE_START,
  TEMPLATE_TYPE_SUCCESS,
  TEMPLATE_TYPE_FAILED,
  TEMPLATE_UNPUBLISH_START,
  TEMPLATE_UNPUBLISH_SUCCESS,
  TEMPLATE_UNPUBLISH_FAILED,
  FETCH_BLANK_TEMPLATE_SIZE_START,
  FETCH_BLANK_TEMPLATE_SIZE_SUCCESS,
  FETCH_BLANK_TEMPLATE_SIZE_FAILED,
  TEMPLATE_CATEGORIES_BY_TYPE_START,
  TEMPLATE_CATEGORIES_BY_TYPE_SUCCESS,
  TEMPLATE_CATEGORIES_BY_TYPE_FAILED,
  TEMPLATE_SEARCH_START,
  TEMPLATE_SEARCH_SUCCESS,
  TEMPLATE_SEARCH_FAILED,
} from "../actions/actionTypes";

const initialState = {
  template_groups: JSON.parse(localStorage.getItem("template_groups")),
  template_types: {},
  templates: {},
};
export const templateReducer = (originalState = initialState, action) => {
  const state = {
    ...originalState,
    type: action.type,
    flashMessage: null,
    status: action?.error?.response?.status ?? null,
  };

  switch (action.type) {
    case TEMPLATE_GROUP_START:
      return { ...state, loading: true };

    case TEMPLATE_GROUP_SUCCESS:
      localStorage.setItem("template_groups", JSON.stringify(action.data.template_groups));
      return { ...state, loading: false, templateGroups: action.data };

    case TEMPLATE_GROUP_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case TEMPLATE_PAGE_SIZE_START:
      return { ...state, loading: true };

    case TEMPLATE_PAGE_SIZE_SUCCESS:
      return { ...state, loading: false, templatePageSizes: action.data?.template_page_sizes };

    case TEMPLATE_PAGE_SIZE_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case TEMPLATE_DETAILS_FETCH_START:
      return { ...state, loading: true, templateDetails: { data: { id: action.templateId } } };

    case TEMPLATE_DETAILS_FETCH_SUCCESS:
      return { ...state, loading: false, templateDetails: { data: action?.result }, success: true };

    case TEMPLATE_DETAILS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: { message: action.error },
        templateDetails: { ...state.templateDetails, success: false },
      };

    case TEMPLATES_START:
      return { ...state, loading: true };

    case TEMPLATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        total_count: action.data.total_count,
        templates: {
          ...state.templates,
          ...action.data.templates,
        },
      };
    }

    case TEMPLATES_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case TEMPLATE_TYPE_START:
      return { ...state, loading: true };

    case TEMPLATE_TYPE_SUCCESS:
      localStorage.setItem("template_groups", JSON.stringify(action.templateGroup));
      return { ...state, loading: false, ...action.data, templateGroups: { template_groups: action.templateGroup } };

    case TEMPLATE_TYPE_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case TEMPLATE_UNPUBLISH_START:
      return { ...state, loading: false };

    case TEMPLATE_UNPUBLISH_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case TEMPLATE_UNPUBLISH_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case FETCH_BLANK_TEMPLATE_SIZE_START:
      return { ...state, loading: false };

    case FETCH_BLANK_TEMPLATE_SIZE_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case FETCH_BLANK_TEMPLATE_SIZE_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case TEMPLATE_CATEGORIES_BY_TYPE_START:
      return { ...state, loading: false };

    case TEMPLATE_CATEGORIES_BY_TYPE_SUCCESS:
      return { ...state, loading: false, superadmin: { ...action.result } };

    case TEMPLATE_CATEGORIES_BY_TYPE_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case TEMPLATE_SEARCH_START:
      return { ...state, loading: false };

    case TEMPLATE_SEARCH_SUCCESS:
      return { ...state, loading: false, templateSearch: { ...state.templateSearch, [action.filterType]: action.result } };

    case TEMPLATE_SEARCH_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    default:
      return state;
  }
};
