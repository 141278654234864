import cx from "classnames";
import React from "react";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";
import smScreenPng from "../../assets/images/dhp-sm-screen.png";
import smScreenWebp from "../../assets/images/dhp-sm-screen.webp";
import mobileScreenPng from "../../assets/images/dhp-mobile.png";
import mobileScreenWebp from "../../assets/images/dhp-mobile.webp";
import Image from "../../components/ui/image";
import Logo from "../../assets/images/logo-icon.svg";
import { Link } from "react-router-dom";

let style = Object.assign({}, global);

const BrowserScreenSize = ({ header }) => {
  return (
    <>
      {header && (
        <div className={style["small-screen-header"]}>
          <div className={style["logo-wraper"]}>
            <Link to="/">
              <span className={cx(style["logo-icon"], style["mr-2"])}>
                <img src={Logo} width="30" alt="" />
              </span>
              <span className={style["logo-text"]}>
                <span className={style["logo-red"]}>D</span>
                <span className={style["logo-yellow"]}>o</span>
                <span className={style["logo-green"]}>c</span>
                Hipo
              </span>
            </Link>
          </div>
        </div>
      )}
      <div className={cx(style["small-screen-wrap"], { [style["bg-white"]]: !header })}>
        {window.innerWidth >= 768 && (
          <div className={style["screen-item"]}>
            <Image WebpImage={smScreenWebp} PngImage={smScreenPng} altText="No records" />
            <h4 className={cx(style["mt-4"], style["fw-6"])}> Your browser window is too small</h4>
            <p>
              DocHipo works the best on browsers that are at least 992px wide. <br /> Please increase your browser
              window size.
            </p>
          </div>
        )}
        {window.innerWidth < 768 && (
          <div className={cx(style["screen-item"], style["mobile-screen"])}>
            <Image WebpImage={mobileScreenWebp} PngImage={mobileScreenPng} altText="No records" />
            <h2 className={cx(style["mt-4"], style["fw-7"])}> On Mobile?</h2>
            <p>
              DocHipo for mobile devices would be available in the future. <br />
              <br /> Meanwhile, please visit dochipo.com from your desktop browser and start creating stunning
              documents.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

// Props type validation
BrowserScreenSize.propTypes = {
  header: PropTypes.bool,
};

export default BrowserScreenSize;
