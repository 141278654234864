import {
  COMPANY_FETCH_START,
  COMPANY_FETCH_SUCCESS,
  COMPANY_FETCH_FAILED,
  COMPANY_SWITCH_START,
  COMPANY_SWITCH_SUCCESS,
  COMPANY_SWITCH_FAILED,
  GET_USER_LIST_START,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILED,
  ACTIVATE_USERS_SUCCESS,
  DEACTIVATE_USERS_SUCCESS,
  REINVITE_USERS_SUCCESS,
  REINVITE_USERS_START,
  ACTIVATE_USERS_START,
  DEACTIVATE_USERS_START,
  FETCH_COMPANY_USER_INFO_START,
  FETCH_COMPANY_USER_INFO_SUCCESS,
  FETCH_COMPANY_USER_INFO_FAILED,
  DELETE_USERS_START,
  DELETE_USERS_FAILED,
  DELETE_USERS_SUCCESS,
  REINVITE_USERS_FAILED,
  CREATE_COMPANY_START,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILED,
  FETCH_ACTIVE_COMPANY_USERS_SUCCESS,
  GET_CONNECTED_ACCOUNTS_START,
  GET_CONNECTED_ACCOUNTS_SUCCESS,
  GET_CONNECTED_ACCOUNTS_FAILED,
  DELETE_CONNECTED_ACCOUNT_START,
  DELETE_CONNECTED_ACCOUNT_SUCCESS,
  DELETE_CONNECTED_ACCOUNT_FAILED,
  FETCH_COMPANY_WISE_CONFIG_USER_SUCCESS,
  UPDATE_COMPANY_WISE_CONFIG_USER_SUCCESS,
  SEND_CHANGE_EMAIL_OTP_START,
  SEND_CHANGE_EMAIL_OTP_SUCCESS,
  SEND_CHANGE_EMAIL_OTP_FAILED,
  CHANGE_ACCOUNT_EMAIL_START,
  CHANGE_ACCOUNT_EMAIL_SUCCESS,
  CHANGE_ACCOUNT_EMAIL_FAILED,
  UPDATE_USER_TOUR_CONFIG_START,
  UPDATE_USER_TOUR_CONFIG_SUCCESS,
  UPDATE_USER_TOUR_CONFIG_FAILED,
  ACTIVATE_USERS_FAILED,
  FETCH_TYPEFORM_FORMS_SUCCESS,
  FETCH_JOTFORM_FORMS_START,
  FETCH_JOTFORM_FORMS_SUCCESS,
  FETCH_JOTFORM_FORMS_FAILED,
  CREATE_JOTFORM_FORMS_START,
  CREATE_JOTFORM_FORMS_SUCCESS,
  CREATE_JOTFORM_FORMS_FAILED,
  FETCH_BRAND_LIST_START,
  FETCH_BRAND_LIST_SUCCESS,
  FETCH_BRAND_LIST_FAILED,
  FETCH_BRAND_DETAILS_START,
  FETCH_BRAND_DETAILS_SUCCESS,
  FETCH_BRAND_DETAILS_FAILED,
  UPDATE_BRAND_START,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAILED,
  DELETE_BRAND_ASSET_START,
  DELETE_BRAND_ASSET_SUCCESS,
  DELETE_BRAND_ASSET_FAILED,
  FETCH_FACEBOOK_ALBUMS_START,
  FETCH_FACEBOOK_ALBUMS_SUCCESS,
  FETCH_FACEBOOK_ALBUMS_FAILED,
  FETCH_FACEBOOK_ALBUM_IMAGES_START,
  FETCH_FACEBOOK_ALBUM_IMAGES_SUCCESS,
  FETCH_FACEBOOK_ALBUM_IMAGES_FAILED,
  UNPUBLISH_COMPANY_TEMPLATE_START,
  UNPUBLISH_COMPANY_TEMPLATE_SUCCESS,
  UNPUBLISH_COMPANY_TEMPLATE_FAILED,
  FETCH_COMPANY_TEMPLATES_START,
  FETCH_COMPANY_TEMPLATES_SUCCESS,
  FETCH_COMPANY_TEMPLATES_FAILED,
  FETCH_COMPANY_TEMPLATE_DETAILS_START,
  FETCH_COMPANY_TEMPLATE_DETAILS_SUCCESS,
  FETCH_COMPANY_TEMPLATE_DETAILS_FAILED,
  FETCH_COMPANY_TEMPLATE_BRANDS_START,
  FETCH_COMPANY_TEMPLATE_BRANDS_SUCCESS,
  FETCH_COMPANY_TEMPLATE_BRANDS_FAILED,
  GET_COMPANY_PUBLIC_CONFIG_START,
  GET_COMPANY_PUBLIC_CONFIG_SUCCESS,
  GET_COMPANY_PUBLIC_CONFIG_FAILED,
} from "../actions/actionTypes";

const initialState = {
  companies: JSON.parse(localStorage.getItem("companies")),
};

const mergeAlbumData = (existingData, newData) => {
  // Check if the album ID already exists in the existing data
  const albumId = Object.keys(newData.album_images)[0];
  if (existingData.album_images.hasOwnProperty(albumId)) {
    // Album ID already exists, merge the images and update paging
    existingData.album_images[albumId].images = [
      ...existingData.album_images[albumId].images,
      ...newData.album_images[albumId].images,
    ];

    existingData.album_images[albumId].paging = newData.album_images[albumId].paging;
  } else {
    // Album ID doesn't exist, add the new album data
    existingData.album_images = {
      ...existingData.album_images,
      ...newData.album_images,
    };
  }

  return existingData;
};

export const companyReducer = (originalState = initialState, action) => {
  const state = {
    ...originalState,
    type: action.type,
    flashMessage: null,
    status: action?.error?.response?.status ?? null,
  };

  switch (action.type) {
    case COMPANY_FETCH_START:
      return { ...state, loading: true };

    case COMPANY_FETCH_SUCCESS:
      localStorage.setItem("companies", JSON.stringify(action.result?.companies ?? {}));
      return { ...state, loading: false, ...action.result };

    case COMPANY_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: { message: action.error },
        flashMessage: { type: "error", message: "Something went wrong! Please try again later." },
      };

    case COMPANY_SWITCH_START:
      return { ...state, loading: false };

    case COMPANY_SWITCH_SUCCESS:
      localStorage.removeItem("allFonts");
      localStorage.removeItem("documents");
      return { ...state, loading: false, ...action.result };

    case COMPANY_SWITCH_FAILED:
      return {
        ...state,
        loading: false,
        error: { message: action.error },
        flashMessage: { type: "error", message: "Something went wrong! Please try again later." },
      };

    case GET_USER_LIST_START:
      return { ...state, loading: true, reloadUserList: null, userListViewType: null, userActivationFailedMsg: null };

    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        users: action.userList,
        total: action.total,
        loading: false,
        userInfo: null,
        userListViewType: action.viewType,
        isSearchKey: action.search_key,
        isDeleted: null,
      };

    case GET_USER_LIST_FAILED:
      // TODO: Need to solve state mutation error
      const isEditor = window.location.pathname.includes("editor");
      const msg = isEditor ? null : { type: "error", message: "Something went wrong! Please try again later." };
      return {
        ...state,
        loading: false,
        userListViewType: null,
        error: { message: action.error },
        flashMessage: msg,
      };

    case ACTIVATE_USERS_START:
      return { ...state, loading: true, userActivationFailedMsg: null };

    case ACTIVATE_USERS_FAILED:
      return { ...state, loading: false, userActivationFailedMsg: action.data.message };

    case ACTIVATE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: null,
        total: null,
        userActivationFailedMsg: null,
        flashMessage: { type: "success", message: "User Activated" },
      };

    case REINVITE_USERS_START:
      return { ...state, loading: true };

    case DEACTIVATE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: null,
        total: null,
        flashMessage: { type: "success", message: "User Deactivated" },
      };

    case DEACTIVATE_USERS_START:
      return { ...state, loading: true };

    case REINVITE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        flashMessage: { type: "success", message: "Invitation Email Sent" },
        reloadUserList: true,
        users: null,
        total: null,
      };

    case REINVITE_USERS_FAILED:
      return {
        ...state,
        loading: false,
        flashMessage: { type: "error", message: action.data.message },
      };

    case FETCH_COMPANY_USER_INFO_START:
      return {
        ...state,
        loading: true,
      };

    case FETCH_COMPANY_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: { [action.userId]: action.data },
      };

    case FETCH_COMPANY_USER_INFO_FAILED:
      return {
        ...state,
        loading: false,
      };

    case DELETE_USERS_START:
      return { ...state, loading: true, isDeleted: null };

    case DELETE_USERS_FAILED:
      return { ...state, loading: false };

    case DELETE_USERS_SUCCESS:
      return { ...state, loading: false, users: null, isDeleted: true };

    case CREATE_COMPANY_START:
      return { ...state, loading: true };

    case CREATE_COMPANY_SUCCESS:
      return { ...state, loading: false, ...action.result, id: action.result.company_id };

    case CREATE_COMPANY_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case FETCH_ACTIVE_COMPANY_USERS_SUCCESS:
      return { ...state, activeUsers: action.activeUsers };

    case GET_CONNECTED_ACCOUNTS_START:
      return { ...state, loading: true };

    case GET_CONNECTED_ACCOUNTS_SUCCESS:
      return { ...state, loading: false, thirdPartyAccounts: action.result, deletConnectedAccount: false };

    case FETCH_TYPEFORM_FORMS_SUCCESS:
      return { ...state, loading: false, thirdPartyForms: { [action.assetName]: action.forms } };

    case FETCH_JOTFORM_FORMS_START:
      return { ...state, loading: false };

    case FETCH_JOTFORM_FORMS_SUCCESS:
      return { ...state, loading: false, jotformList: action.results.details };

    case FETCH_JOTFORM_FORMS_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case CREATE_JOTFORM_FORMS_START:
      return { ...state, loading: false };

    case CREATE_JOTFORM_FORMS_SUCCESS:
      return { ...state, loading: false, jotFormAuth: action.results };

    case CREATE_JOTFORM_FORMS_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case GET_CONNECTED_ACCOUNTS_FAILED:
      return {
        ...state,
        loading: false,
        error: { message: action.error },
        flashMessage: { type: "error", message: "Something went wrong! Please try again later." },
      };

    case DELETE_CONNECTED_ACCOUNT_START:
      return { ...state, loading: true };

    case DELETE_CONNECTED_ACCOUNT_SUCCESS:
      return { ...state, loading: false, deletConnectedAccount: action, thirdPartyForms: null, jotformList: null };

    case DELETE_CONNECTED_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
        error: { message: action.error },
        flashMessage: { type: "error", message: "Something went wrong! Please try again later." },
      };

    case FETCH_COMPANY_WISE_CONFIG_USER_SUCCESS:
      return { ...state, configData: { ...state.configData, [action.slug]: action.respData } };

    case UPDATE_COMPANY_WISE_CONFIG_USER_SUCCESS:
      return { ...state, configData: { ...state.configData, [action.slug]: null } };

    case SEND_CHANGE_EMAIL_OTP_START:
      return { ...state, change_email_info: null, loading: true, change_email: null };

    case SEND_CHANGE_EMAIL_OTP_SUCCESS:
      return { ...state, change_email_info: action.data, loading: false };

    case SEND_CHANGE_EMAIL_OTP_FAILED:
      return { ...state, change_email_info: action.error, loading: false };

    case CHANGE_ACCOUNT_EMAIL_START:
      return { ...state, change_email: null, loading: true };

    case CHANGE_ACCOUNT_EMAIL_SUCCESS:
      return { ...state, change_email: action.data, loading: false };

    case CHANGE_ACCOUNT_EMAIL_FAILED:
      return { ...state, change_email: action.error, loading: false };

    case UPDATE_USER_TOUR_CONFIG_START:
      return { ...state, isTourConfigUpdated: null };

    case UPDATE_USER_TOUR_CONFIG_SUCCESS:
      return { ...state, isTourConfigUpdated: true };

    case UPDATE_USER_TOUR_CONFIG_FAILED:
      return { ...state, isTourConfigUpdated: true };

    case FETCH_BRAND_LIST_START:
      return { ...state, loading: true, brandInfo: null };

    case FETCH_BRAND_LIST_SUCCESS:
      return { ...state, loading: false, brandInfo: { ...action.results } };

    case FETCH_BRAND_LIST_FAILED:
      return { ...state, loading: false };

    case FETCH_BRAND_DETAILS_START:
      return { ...state, loading: true, brandInfo: { ...state.brandInfo, brandDetails: null } };

    case FETCH_BRAND_DETAILS_SUCCESS:
      return { ...state, loading: false, brandInfo: { ...state.brandInfo, brandDetails: action.brandInfo } };

    case FETCH_BRAND_DETAILS_FAILED:
      return { ...state, loading: false };

    case UPDATE_BRAND_START:
      return { ...state, loading: true };

    case UPDATE_BRAND_SUCCESS:
      if (action.hashkey) {
        if (action?.data?.data?.logos)
          return {
            ...state,
            loading: false,
            brandInfo: { ...state.brandInfo, [action.hashkey]: action?.data?.data?.logos },
          };
        else if (action?.data?.data?.images)
          return {
            ...state,
            loading: false,
            brandInfo: { ...state.brandInfo, [action.hashkey]: action?.data?.data?.images },
          };
        else {
          return {
            ...state,
            loading: false,
            brandInfo: { ...state.brandInfo, [action.hashkey]: action?.data?.data?.videos },
          };
        }
      }
      return { ...state, loading: false };

    case UPDATE_BRAND_FAILED:
      return { ...state, loading: false };

    case DELETE_BRAND_ASSET_START:
      return { ...state, brandInfo: { ...state.brandInfo, lastDeletedAsset: null } };

    case DELETE_BRAND_ASSET_SUCCESS:
      return { ...state, brandInfo: { ...state.brandInfo, lastDeletedAsset: action.assetId } };

    case DELETE_BRAND_ASSET_FAILED:
      return { ...state, brandInfo: { ...state.brandInfo, lastDeletedAsset: null } };

    case FETCH_FACEBOOK_ALBUMS_START:
      return { ...state, loading: true };

    case FETCH_FACEBOOK_ALBUMS_SUCCESS:
      return { ...state, loading: false, facebookImages: action.data };

    case FETCH_FACEBOOK_ALBUMS_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case FETCH_FACEBOOK_ALBUM_IMAGES_START:
      return { ...state, loading: true };

    case FETCH_FACEBOOK_ALBUM_IMAGES_SUCCESS:
      const fbImages = mergeAlbumData(JSON.parse(JSON.stringify(state.facebookImages.data)), action.data.data);
      return { ...state, loading: false, facebookImages: { data: fbImages } };

    case FETCH_FACEBOOK_ALBUM_IMAGES_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case UNPUBLISH_COMPANY_TEMPLATE_START:
      return { ...state, loading: false };

    case UNPUBLISH_COMPANY_TEMPLATE_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case UNPUBLISH_COMPANY_TEMPLATE_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case FETCH_COMPANY_TEMPLATES_START:
      return { ...state, loading: true };

    case FETCH_COMPANY_TEMPLATES_SUCCESS:
      return { ...state, loading: false, companyTemplates: action.result };

    case FETCH_COMPANY_TEMPLATES_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case FETCH_COMPANY_TEMPLATE_DETAILS_START:
      return { ...state, loading: false };

    case FETCH_COMPANY_TEMPLATE_DETAILS_SUCCESS:
      const template = { ...action.result.template };

      return {
        ...state,
        loading: false,
        companyTemplateInfo: { data: { ...template, created_by: template.creator } },
      };

    case FETCH_COMPANY_TEMPLATE_DETAILS_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case FETCH_COMPANY_TEMPLATE_BRANDS_START:
      return { ...state, loading: false };

    case FETCH_COMPANY_TEMPLATE_BRANDS_SUCCESS:
      return { ...state, loading: false, companyTemplateBrands: action.result };

    case FETCH_COMPANY_TEMPLATE_BRANDS_FAILED:
      return { ...state, loading: false, error: { message: action.error } };
    
    case GET_COMPANY_PUBLIC_CONFIG_START:
      return { ...state, loading: false };

    case GET_COMPANY_PUBLIC_CONFIG_SUCCESS:
      return { ...state, loading: false, publicCompanyConfig: action?.companyInfo };

    case GET_COMPANY_PUBLIC_CONFIG_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    default:
      return state;
  }
};
