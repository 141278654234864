import {
  DOCUMENT_FETCH_START,
  DOCUMENT_FETCH_SUCCESS,
  DOCUMENT_FETCH_FAILED,
  DOCUMENT_UPDATE_START,
  DOCUMENT_UPDATE_SUCCESS,
  DOCUMENT_UPDATE_FAILED,
  DOCUMENT_CLONE_START,
  DOCUMENT_CLONE_SUCCESS,
  DOCUMENT_CLONE_FAILED,
  DOCUMENT_SHARE_START,
  DOCUMENT_SHARE_SUCCESS,
  DOCUMENT_SHARE_FAILED,
  DOCUMENT_SHARE_UPDATE_PERMISSION_START,
  DOCUMENT_SHARE_UPDATE_PERMISSION_SUCCESS,
  DOCUMENT_SHARE_UPDATE_PERMISSION_FAILED,
  DOCUMENT_SHARE_DELETE_USER_START,
  DOCUMENT_SHARE_DELETE_USER_SUCCESS,
  DOCUMENT_SHARE_DELETE_USER_FAILED,
  DOCUMENT_SHARE_INFO_START,
  DOCUMENT_SHARE_INFO_SUCCESS,
  DOCUMENT_SHARE_INFO_FAILED,
  DOCUMENT_PUBLISH_START,
  DOCUMENT_PUBLISH_SUCCESS,
  DOCUMENT_PUBLISH_FAILED,
  DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_START,
  DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_SUCCESS,
  DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_FAILED,
  DOCUMENT_PUBLISH_SET_PASSWORD_START,
  DOCUMENT_PUBLISH_SET_PASSWORD_SUCCESS,
  DOCUMENT_PUBLISH_SET_PASSWORD_FAILED,
  DOCUMENT_PUBLISH_UNSET_PASSWORD_START,
  DOCUMENT_PUBLISH_UNSET_PASSWORD_SUCCESS,
  DOCUMENT_PUBLISH_UNSET_PASSWORD_FAILED,
  DOCUMENT_UNPUBLISH_START,
  DOCUMENT_UNPUBLISH_SUCCESS,
  DOCUMENT_UNPUBLISH_FAILED,
  DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_START,
  DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_SUCCESS,
  DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_FAILED,
  DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_START,
  DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_SUCCESS,
  DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_FAILED,
  DOCUMENT_TRANSFER_START,
  DOCUMENT_TRANSFER_SUCCESS,
  DOCUMENT_TRANSFER_FAILED,
  DOCUMENT_DELETE_START,
  DOCUMENT_DELETE_SUCCESS,
  DOCUMENT_DELETE_FAILED,
  DOCUMENT_DETAILS_FETCH_START,
  DOCUMENT_DETAILS_FETCH_SUCCESS,
  DOCUMENT_DETAILS_FETCH_FAILED,
  DOCUMENT_COMMENTS_START,
  DOCUMENT_COMMENTS_SUCCESS,
  DOCUMENT_COMMENTS_FAILED,
  INSERT_COMMENTS_START,
  INSERT_COMMENTS_SUCCESS,
  INSERT_COMMENTS_FAILED,
  APPLY_LIKE_START,
  APPLY_LIKE_SUCCESS,
  APPLY_LIKE_FAILED,
  UPDATE_COMMENT_START,
  UPDATE_COMMENT_SUCCESS,
  UPDATE_COMMENT_FAILED,
  DELETE_COMMENT_START,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILED,
  FETCH_DOCUMENT_VERSION_START,
  FETCH_DOCUMENT_VERSION_SUCCESS,
  FETCH_DOCUMENT_VERSION_FAILED,
  RESTORE_DOCUMENT_VERSION_SUCCESS,
  RESTORE_DOCUMENT_VERSION_START,
  PUBLISHED_DOCUMENT_FETCH_START,
  PUBLISHED_DOCUMENT_FETCH_SUCCESS,
  PUBLISHED_DOCUMENT_FETCH_FAILED,
  DOCUMENT_INSERT_START,
  DOCUMENT_INSERT_SUCCESS,
  DOCUMENT_INSERT_FAILED,
  FETCH_DOCUMENT_VERSION_INFO_START,
  FETCH_DOCUMENT_VERSION_INFO_SUCCESS,
  FETCH_DOCUMENT_VERSION_INFO_FAILED,
  CHECK_DOCUMENT_CREATE_PERMISSION_SUCCESS,
  CHECK_DOCUMENT_CREATE_PERMISSION_START,
  UPDATE_DOCUMENT_REDUCER,
  SEND_DOCUMENT_EMAIL_START,
  SEND_DOCUMENT_EMAIL_SUCCESS,
  SEND_DOCUMENT_EMAIL_FAILED,
  FETCH_SENT_EMAIL_LIST_START,
  FETCH_SENT_EMAIL_LIST_SUCCESS,
  FETCH_SENT_EMAIL_LIST_FAILED,
  PUBLISH_COMPANY_TEMPLATE_START,
  PUBLISH_COMPANY_TEMPLATE_SUCCESS,
  PUBLISH_COMPANY_TEMPLATE_FAILED,
  PUBLISH_COMPANY_TEMPLATE_INFO_START,
  PUBLISH_COMPANY_TEMPLATE_INFO_SUCCESS,
  PUBLISH_COMPANY_TEMPLATE_INFO_FAILED,
  FETCH_SAVED_FILTER_START,
  FETCH_SAVED_FILTER_SUCCESS,
  FETCH_SAVED_FILTER_FAILED,
  DELETE_SAVED_FILTER_START,
  DELETE_SAVED_FILTER_SUCCESS,
  DELETE_SAVED_FILTER_FAILED,
  RENAME_SAVED_FILTER_START,
  RENAME_SAVED_FILTER_SUCCESS,
  RENAME_SAVED_FILTER_FAILED,
  FETCH_APPLIED_FILTER_DOCUMENT_LIST_START,
  FETCH_APPLIED_FILTER_DOCUMENT_LIST_SUCCESS,
  FETCH_APPLIED_FILTER_DOCUMENT_LIST_FAILED,
  FETCH_APPLY_FILTER_DOCUMENT_START,
  FETCH_APPLY_FILTER_DOCUMENT_SUCCESS,
  FETCH_APPLY_FILTER_DOCUMENT_FAILED,
  FETCH_FILTER_RECORDS_START,
  FETCH_FILTER_RECORDS_SUCCESS,
  FETCH_FILTER_RECORDS_FAILED,
  CREATE_SAVE_FILTER_START,
  CREATE_SAVE_FILTER_SUCCESS,
  CREATE_SAVE_FILTER_FAILED,
  UPDATE_SAVED_FILTER_START,
  UPDATE_SAVED_FILTER_SUCCESS,
  UPDATE_SAVED_FILTER_FAILED,
  UPDATE_SAVED_FILTER_COLOR_START,
  UPDATE_SAVED_FILTER_COLOR_SUCCESS,
  UPDATE_SAVED_FILTER_COLOR_FAILED,
  DOCUMENT_PUBLISH_HANDLE_BADGE_START,
  DOCUMENT_PUBLISH_HANDLE_BADGE_SUCCESS,
  DOCUMENT_PUBLISH_HANDLE_BADGE_FAILED,
  DOCUMENT_PUBLISH_EMAIL_VERIFICATION_START,
  DOCUMENT_PUBLISH_EMAIL_VERIFICATION_SUCCESS,
  DOCUMENT_PUBLISH_EMAIL_VERIFICATION_FAILED,
  VERIFY_EMAIL_PUBLISHED_DOCUMENT_START,
  VERIFY_EMAIL_PUBLISHED_DOCUMENT_SUCCESS,
  VERIFY_EMAIL_PUBLISHED_DOCUMENT_FAILED,
  PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_START,
  PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_SUCCESS,
  PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_FAILED,
} from "../actions/actionTypes";

const initialState = {
  documents: [],
  total_count: 0,
};

export const documentReducer = (originalState = initialState, action) => {
  const state = {
    ...originalState,
    type: action.type,
    flashMessage: null,
    status: action?.error?.response?.status ?? null,
    filterRecords: null,
    saveFilter : null
  };

  switch (action.type) {
    case DOCUMENT_FETCH_START:
      return { ...state, loading: true, documentDetails: null };

    case DOCUMENT_FETCH_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: { message: action.error },
        flashMessage: { type: "error", message: "Something went wrong! Please try again later." },
      };

    case DOCUMENT_UPDATE_START:
      return { ...state, loading: false };

    case DOCUMENT_UPDATE_SUCCESS: {
      let documentDetails = { data: { ...state?.documentDetails?.data, ...action.payload } };
      return { ...state, loading: false, ...action.result, documentDetails };
    }

    case DOCUMENT_UPDATE_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_CLONE_START:
      return { ...state, loading: false };

    case DOCUMENT_CLONE_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_CLONE_FAILED:
      return {
        ...state,
        loading: false,
        error: { message: action.error },
        flashMessage: { type: "error", message: "Something went wrong! Please try again later." },
      };

    case DOCUMENT_SHARE_START:
      return { ...state, loading: false };

    case DOCUMENT_SHARE_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_SHARE_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_SHARE_UPDATE_PERMISSION_START:
      return { ...state, loading: false };

    case DOCUMENT_SHARE_UPDATE_PERMISSION_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_SHARE_UPDATE_PERMISSION_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_SHARE_DELETE_USER_START:
      return { ...state, loading: false };

    case DOCUMENT_SHARE_DELETE_USER_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_SHARE_DELETE_USER_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_SHARE_INFO_START:
      return { ...state, loading: false };

    case DOCUMENT_SHARE_INFO_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_SHARE_INFO_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_PUBLISH_START:
      return { ...state, loading: false };

    case DOCUMENT_PUBLISH_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_PUBLISH_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_START:
      return { ...state, loading: false };

    case DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_PUBLISH_SET_PASSWORD_START:
      return { ...state, loading: false };

    case DOCUMENT_PUBLISH_SET_PASSWORD_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_PUBLISH_SET_PASSWORD_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_PUBLISH_UNSET_PASSWORD_START:
      return { ...state, loading: false };

    case DOCUMENT_PUBLISH_UNSET_PASSWORD_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_PUBLISH_UNSET_PASSWORD_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_UNPUBLISH_START:
      return { ...state, loading: false };

    case DOCUMENT_UNPUBLISH_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_UNPUBLISH_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_PUBLISH_HANDLE_BADGE_START:
      return { ...state, loading: false };

    case DOCUMENT_PUBLISH_HANDLE_BADGE_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_PUBLISH_HANDLE_BADGE_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_PUBLISH_EMAIL_VERIFICATION_START:
      return { ...state, loading: false };

    case DOCUMENT_PUBLISH_EMAIL_VERIFICATION_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_PUBLISH_EMAIL_VERIFICATION_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_START:
      return { ...state, loading: false };

    case DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_START:
      return { ...state, loading: false };

    case DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_PUBLISH_TO_TEMPLATE_LIBRARY_INFO_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_TRANSFER_START:
      return { ...state, loading: false };

    case DOCUMENT_TRANSFER_SUCCESS:
      let keys = {
        type: action.result?.success === false ? "custom" : "success",
        message: "Document is transferred successfully",
      };
      if (action.result?.success === false) {
        keys["component"] = "transfer";
        keys["message"] = "Document could not be transferred";
      }

      return {
        ...state,
        loading: false,
        ...action.result,
        flashMessage: { ...keys },
      };

    case DOCUMENT_TRANSFER_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_DELETE_START:
      return { ...state, loading: false };

    case DOCUMENT_DELETE_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_DELETE_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_DETAILS_FETCH_START:
      return {
        ...state,
        loading: action?.loading ?? true,
        versionRestored: null,
        documentDetails: { data: { id: action.documentId } },
        error: null,
      };

    case DOCUMENT_DETAILS_FETCH_SUCCESS:
      return { ...state, loading: false, documentDetails: { ...state.documentDetails, ...action.result }, error: null };

    case DOCUMENT_DETAILS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: { message: action.error.message },
        documentDetails: { ...state.documentDetails, success: false },
      };

    case DOCUMENT_COMMENTS_START:
      return { ...state, loading: true };

    case DOCUMENT_COMMENTS_SUCCESS:
      return { ...state, loading: false, comments: action?.data?.comments };

    case DOCUMENT_COMMENTS_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case INSERT_COMMENTS_START:
      return { ...state, loading: true };

    case INSERT_COMMENTS_SUCCESS:
      return { ...state, loading: false, newCommentReply: action };

    case INSERT_COMMENTS_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case APPLY_LIKE_START:
      return { ...state, loading: true };

    case APPLY_LIKE_SUCCESS:
      return { ...state, loading: false, likeCommentReply: action };

    case APPLY_LIKE_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case UPDATE_COMMENT_START:
      return { ...state, loading: true };

    case UPDATE_COMMENT_SUCCESS:
      return { ...state, loading: false, updateCommentReply: action };

    case UPDATE_COMMENT_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DELETE_COMMENT_START:
      return { ...state, loading: true };

    case DELETE_COMMENT_SUCCESS:
      return { ...state, loading: false, deleteCommentReply: action };

    case DELETE_COMMENT_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case FETCH_DOCUMENT_VERSION_START:
      return { ...state, loading: true, versionList: null };

    case FETCH_DOCUMENT_VERSION_FAILED:
      return { ...state, loading: false };

    case FETCH_DOCUMENT_VERSION_SUCCESS:
      return { ...state, loading: false, versionList: action.data.versions };

    case RESTORE_DOCUMENT_VERSION_SUCCESS:
      return {
        ...state,
        versionRestored: true,
        flashMessage: { type: "success", message: "Document version successfully restored" },
      };

    case RESTORE_DOCUMENT_VERSION_START:
      return { ...state, versionRestored: null };

    case PUBLISHED_DOCUMENT_FETCH_START:
      return { ...state, loading: true };

    case PUBLISHED_DOCUMENT_FETCH_SUCCESS:
      return { ...state, loading: false, documentDetails: action.result };

    case PUBLISHED_DOCUMENT_FETCH_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_START:
      return { ...state, loading: true };

    case PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_SUCCESS:
      return { ...state, loading: false, documentDetails: action.result };

    case PUBLISHED_OTP_VERIFIED_DOCUMENT_FETCH_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case DOCUMENT_INSERT_START:
      return { ...state, loading: false };

    case DOCUMENT_INSERT_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case DOCUMENT_INSERT_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case FETCH_DOCUMENT_VERSION_INFO_START:
      return { ...state, versionDetailInfo: null };

    case FETCH_DOCUMENT_VERSION_INFO_SUCCESS:
      return { ...state, versionDetailInfo: action.result };

    case FETCH_DOCUMENT_VERSION_INFO_FAILED:
      return { ...state, versionDetailInfo: null };

    case CHECK_DOCUMENT_CREATE_PERMISSION_START:
      return { ...state, documentCreateStatus: null };

    case CHECK_DOCUMENT_CREATE_PERMISSION_SUCCESS:
      return { ...state, documentCreateStatus: action.result.message };

    case UPDATE_DOCUMENT_REDUCER:
      return { ...state, ...action.payload };

    case SEND_DOCUMENT_EMAIL_START:
      return { ...state, loading: false, loadingEmailSend: true };

    case SEND_DOCUMENT_EMAIL_SUCCESS:
      return { ...state, loading: false, loadingEmailSend: false, ...action.result };

    case SEND_DOCUMENT_EMAIL_FAILED:
      return { ...state, loading: false, loadingEmailSend: false, error: { message: action.error } };

    case FETCH_SENT_EMAIL_LIST_START:
      return { ...state, loading: false, lodingEmailList: true };

    case FETCH_SENT_EMAIL_LIST_SUCCESS:
      return { ...state, loading: false, lodingEmailList: false, sentEmailList: action.result };

    case FETCH_SENT_EMAIL_LIST_FAILED:
      return { ...state, loading: false, lodingEmailList: false, error: { message: action.error } };

    case PUBLISH_COMPANY_TEMPLATE_START:
      return { ...state, loading: false };

    case PUBLISH_COMPANY_TEMPLATE_SUCCESS:
      return { ...state, loading: false, ...action.result };

    case PUBLISH_COMPANY_TEMPLATE_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case PUBLISH_COMPANY_TEMPLATE_INFO_START:
      return { ...state, loading: false };

    case PUBLISH_COMPANY_TEMPLATE_INFO_SUCCESS:
      return { ...state, loading: false, companyTemplateInfo: action.result };

    case PUBLISH_COMPANY_TEMPLATE_INFO_FAILED:
      return { ...state, loading: false, error: { message: action.error } };

    case FETCH_SAVED_FILTER_START:
      return { ...state, savedFilterListLoading: true };

    case FETCH_SAVED_FILTER_SUCCESS:
      return { ...state, savedFilterListLoading: false, savedFilterList: action.result };

    case FETCH_SAVED_FILTER_FAILED:
      return { ...state, savedFilterListLoading: false, error: { message: action.error } };

    case DELETE_SAVED_FILTER_START:
      return { ...state };

    case DELETE_SAVED_FILTER_SUCCESS:
      return { ...state, ...action.result };

    case DELETE_SAVED_FILTER_FAILED:
      return { ...state, error: { message: action.error } };

    case RENAME_SAVED_FILTER_START:
      return { ...state, loadingRename: true };

    case RENAME_SAVED_FILTER_SUCCESS:
      return { ...state, loadingRename: false, ...action.result };

    case RENAME_SAVED_FILTER_FAILED:
      return { ...state, loadingRename: false, error: { message: action.error } };

    case FETCH_APPLIED_FILTER_DOCUMENT_LIST_START:
      return { ...state, loading: true, loadingDocumentList: true };

    case FETCH_APPLIED_FILTER_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingDocumentList: false,
        documents: action.result.documents,
        total_count: action.result.total_count,
        success: action.result.success,
        superadmin: action.result.superadmin,
      };

    case FETCH_APPLIED_FILTER_DOCUMENT_LIST_FAILED:
      return { ...state, loading: false, loadingDocumentList: false, error: { message: action.error } };

    case FETCH_APPLY_FILTER_DOCUMENT_START:
      return { ...state, loading: true, loadingDocument: true, documentDetails: null };

    case FETCH_APPLY_FILTER_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingDocument: false,
        documents: action.result.documents,
        total_count: action.result.total_count,
        success: action.result.success,
        superadmin: action.result.superadmin,
      };

    case FETCH_APPLY_FILTER_DOCUMENT_FAILED:
      return { ...state, loading: false, loadingDocument: false, error: { message: action.error } };

    case FETCH_FILTER_RECORDS_START:
      return { ...state, loadingFetchRecords: true };

    case FETCH_FILTER_RECORDS_SUCCESS:
      return { ...state, loadingFetchRecords: false, filterRecords: action.result };

    case FETCH_FILTER_RECORDS_FAILED:
      return { ...state, loadingFetchRecords: false, error: { message: action.error } };

    case CREATE_SAVE_FILTER_START:
      return { ...state, loadingSaveFilter: true };

    case CREATE_SAVE_FILTER_SUCCESS:
      return { ...state, loadingSaveFilter: false, saveFilter: action.result };

    case CREATE_SAVE_FILTER_FAILED:
      return { ...state, loadingSaveFilter: false, error: { message: action.error } };

    case UPDATE_SAVED_FILTER_START:
      return { ...state, loadingUpdateFilter: true };

    case UPDATE_SAVED_FILTER_SUCCESS:
      return { ...state, loadingUpdateFilter: false, updatedSavedFilter: action.result };

    case UPDATE_SAVED_FILTER_FAILED:
      return { ...state, loadingUpdateFilter: false, error: { message: action.error } };

    case UPDATE_SAVED_FILTER_COLOR_START:
      return { ...state, loadingColorChange: true };

    case UPDATE_SAVED_FILTER_COLOR_SUCCESS:
      return { ...state, loadingColorChange: false, ...action.result };

    case UPDATE_SAVED_FILTER_COLOR_FAILED:
      return { ...state, loadingColorChange: false, error: { message: action.error } };

    case VERIFY_EMAIL_PUBLISHED_DOCUMENT_START:
      return { ...state, loading: true, email_verification_error: null };

    case VERIFY_EMAIL_PUBLISHED_DOCUMENT_SUCCESS:
      return { ...state, loading: false, documentDetails: action.result };

    case VERIFY_EMAIL_PUBLISHED_DOCUMENT_FAILED:
      return { ...state, loading: false, email_verification_error: { message: action.error?.response?.data?.message } };

    default:
      return state;
  }
};
